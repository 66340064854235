.banner {
  position: relative; }
  @media (max-width: 720px) {
    .banner .swiper-button-next,
    .banner .swiper-button-prev {
      width: 50px;
      height: 50px; }
      .banner .swiper-button-next::after,
      .banner .swiper-button-prev::after {
        font-size: 24px; } }
  .banner .social a {
    transition: all 0.3s; }
    .banner .social a:hover {
      transform: scale(1.2); }
    .banner .social a:active {
      filter: brightness(0.8); }
  @media (max-width: 1200px) {
    .banner .social img {
      width: 70px;
      height: 70px; } }
  @media (max-width: 1000px) {
    .banner .social {
      top: 12%; } }
  @media (max-width: 900px) {
    .banner .social {
      top: 8%; } }
  @media (max-width: 600px) {
    .banner .social {
      top: 11%; }
      .banner .social img {
        width: 60px;
        height: 60px; } }
  .banner-swiper {
    position: relative;
    overflow: hidden; }
  .banner_top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 950px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center; }
    .banner_top .new-style-img {
      width: 110px;
      margin-left: 15px;
      border-radius: 20px; }
    .banner_top img.new-style-img + img.new-style-img {
      margin-left: 20px; }
    .banner_top_title {
      max-width: calc(100% - 200px);
      padding: 5%;
      color: #ffffff;
      font-size: 90px;
      line-height: 109px;
      font-weight: 700;
      font-style: normal;
      text-shadow: 0px 20px 20px rgba(0, 0, 0, 0.5); }
      .banner_top_title__1 {
        display: flex;
        align-items: center;
        flex-wrap: nowrap; }
    .banner_top__btn {
      padding: 0% 5% 8% 5%; }
    @media (max-width: 1200px) {
      .banner_top {
        height: 650px; }
        .banner_top_title {
          max-width: calc(100% - 140px);
          font-size: 45px; }
        .banner_top img,
        .banner_top img.new-style-img {
          width: 75px;
          margin-left: 5px; }
        .banner_top img.new-style-img + img.new-style-img {
          margin-left: 10px; } }
    @media (max-width: 900px) {
      .banner_top {
        justify-content: center; } }
    @media (max-width: 720px) {
      .banner_top_title {
        max-width: calc(100% - 120px);
        font-size: 36px; }
      .banner_top img,
      .banner_top img.new-style-img {
        width: 50px; } }
    @media (max-width: 450px) {
      .banner_top_title {
        max-width: calc(100% - 90px);
        font-size: 32px; }
      .banner_top img,
      .banner_top img.new-style-img {
        width: 40px; } }
  .banner_bottom {
    display: flex; }
    @media (max-width: 900px) {
      .banner_bottom {
        flex-direction: column; } }
    .banner_bottom_wrapper-1 {
      display: flex;
      width: 66.6%; }
      @media (max-width: 900px) {
        .banner_bottom_wrapper-1 {
          width: 100%; }
          .banner_bottom_wrapper-1 .banner_bottom_item {
            height: 460px; } }
      @media (max-width: 720px) {
        .banner_bottom_wrapper-1 .banner_bottom_item {
          height: 370px; } }
      @media (max-width: 550px) {
        .banner_bottom_wrapper-1 .banner_bottom_item {
          height: 280px; } }
      @media (max-width: 400px) {
        .banner_bottom_wrapper-1 .banner_bottom_item {
          height: 200px; } }
    .banner_bottom_wrapper-2 {
      width: 33.3%; }
      @media (max-width: 900px) {
        .banner_bottom_wrapper-2 {
          width: 100%; }
          .banner_bottom_wrapper-2.banner_bottom_item {
            height: 940px; } }
      @media (max-width: 720px) {
        .banner_bottom_wrapper-2.banner_bottom_item {
          height: 750px; } }
      @media (max-width: 550px) {
        .banner_bottom_wrapper-2.banner_bottom_item {
          height: 600px; } }
      @media (max-width: 450px) {
        .banner_bottom_wrapper-2.banner_bottom_item {
          height: 500px; } }
      @media (max-width: 370px) {
        .banner_bottom_wrapper-2.banner_bottom_item {
          height: 400px; } }
    .banner_bottom_item {
      height: 750px;
      position: relative; }
      .banner_bottom_item.--w-full {
        width: 100%; }
      .banner_bottom_item__img {
        height: 100%; }
        .banner_bottom_item__img img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
      .banner_bottom_item_title {
        font-family: "Oswald";
        font-style: normal;
        font-weight: 700;
        font-size: 80px;
        line-height: 130%;
        text-align: center;
        text-transform: uppercase;
        height: 120px;
        position: absolute;
        width: 100%;
        top: -60px; }
        @media (max-width: 900px) {
          .banner_bottom_item_title {
            position: static; } }
        @media (max-width: 900px) {
          .banner_bottom_item_title {
            font-size: 40px;
            height: 60px; } }
        .banner_bottom_item_title__1 {
          background: #20a0e9;
          color: #ffffff; }
        .banner_bottom_item_title__2 {
          color: #20a0e9;
          background: #000000;
          border: 7px solid #20a0e9; }
      @media (max-width: 1550px) {
        .banner_bottom_item {
          height: 600px; } }
      @media (max-width: 1400px) {
        .banner_bottom_item {
          height: 500px; } }
      @media (max-width: 1100px) {
        .banner_bottom_item {
          height: 400px; } }

.about {
  display: flex;
  margin: 5%;
  border-left: 13px solid #eb5757;
  width: 90%; }
  @media (max-width: 900px) {
    .about {
      flex-direction: column;
      margin: 5% 0;
      width: 100%; }
      .about .about_title_container,
      .about .about_text {
        width: 100%;
        padding: 0 25px; } }
  .about_title {
    max-width: 325px;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    color: #000000; }
    @media (max-width: 900px) {
      .about_title {
        max-width: none; } }
    .about_title_container {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 900px) {
        .about_title_container {
          justify-content: start;
          margin-bottom: 25px; } }
  .about_text {
    width: 50%;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000; }

.support {
  display: flex; }
  @media (max-width: 1200px) {
    .support {
      flex-direction: column; }
      .support .support_section.section1, .support .support_section.section2, .support .support_section.section3 {
        width: 100%; } }
  .support_section.section1 {
    width: 45%;
    position: relative; }
    .support_section.section1 .section1__title {
      background: #000000;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 44px;
      color: #ffffff;
      padding: 10px 0 10px 10px;
      position: absolute;
      left: 20%;
      bottom: 20px; }
      .support_section.section1 .section1__title span {
        color: yellow;
        background: #20a0e9;
        height: 100%;
        padding: 16px; }
        @media (max-width: 600px) {
          .support_section.section1 .section1__title span {
            padding: 3px 6px; } }
    .support_section.section1 img {
      width: 100%;
      height: 100%; }
  .support_section.section2 {
    width: 25%;
    padding: 4%;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    background-color: #cee6f3; }
  .support_section.section3 {
    width: 30%;
    padding: 6%;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #ffffff;
    background-color: #4f4f4f; }

.donate {
  display: flex;
  margin: 5% 2% 5% 5%;
  border-left: 13px solid #eb5757; }
  @media (max-width: 900px) {
    .donate {
      flex-direction: column;
      margin: 5% 0; }
      .donate .donate_title_container,
      .donate .donate_img {
        width: 100%;
        padding: 0 25px; } }
  @media (max-width: 900px) {
    .donate_title {
      margin-bottom: 20px; }
      .donate_title .donate_title_text {
        max-width: none;
        margin-bottom: 25px; } }
  .donate_title div {
    max-width: 325px;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    color: #000000;
    margin-bottom: 50px; }
  .donate_title_container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .donate_img {
    width: 50%; }
    .donate_img img {
      width: 100%; }

.help__title {
  max-width: 80%;
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 61px;
  /* identical to box height */
  color: #000000;
  text-align: center;
  margin: 0 auto 45px; }

@media (max-width: 900px) {
  .help__title {
    width: 100%;
    margin: 5% auto 45px; } }

@media (max-width: 720px) {
  .help__title {
    font-size: 38px;
    line-height: 48px; } }

.contact-us {
  display: flex;
  margin: 5% 2% 5% 5%;
  border-left: 13px solid #eb5757; }
  @media (max-width: 900px) {
    .contact-us {
      flex-direction: column;
      margin: 5% 0; }
      .contact-us .contact-us_info_container,
      .contact-us .contact-us_form_container {
        width: 100%;
        margin-left: 0px;
        padding: 0 25px; } }
  .contact-us_info_container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 50px; }
  .contact-us_info__1 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    /* identical to box height */
    color: #000000;
    margin-bottom: 30px; }
  .contact-us_info__2 {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 30px; }
  .contact-us_social {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 600px) {
      .contact-us_social img {
        width: 70px;
        height: 70px; } }
    @media (max-width: 450px) {
      .contact-us_social img {
        width: 60px;
        height: 60px; } }
  .contact-us_form {
    display: flex;
    flex-direction: column;
    align-items: center; }
    .contact-us_form_container {
      width: 50%; }
  .contact-us input {
    padding: 19px 38px 19px 20px;
    gap: 10px;
    width: 440px;
    height: 60px;
    background: #f2f2f2;
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    border: none; }
    @media (max-width: 500px) {
      .contact-us input {
        width: 100%; } }
    .contact-us input::placeholder {
      color: #bdbdbd; }

.social {
  position: absolute;
  top: 10%;
  right: 5%;
  display: flex;
  flex-direction: column;
  z-index: 10; }
