@charset "UTF-8";
.input {
  border: 1px solid #F2F2F2;
  border-radius: 8px;
  background: transparent;
  outline: none;
  border-bottom: 1px solid #e0dede;
  padding: 4px 10px; }
  .input__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    margin-top: 30px; }
    .input__wrap textarea:-moz-placeholder {
      color: #586B7A; }
    .input__wrap textarea::-webkit-input-placeholder {
      color: #586B7A; }
    .input__wrap input::-webkit-input-placeholder {
      color: #586B7A; }
    .input__wrap input::-moz-placeholder {
      color: #586B7A; }
    .input__wrap input:-webkit-autofill {
      -webkit-box-shadow: inset 0 0 0 50px #FAFAFA !important;
      /* Цвет фона */
      -webkit-text-fill-color: #000 !important;
      /* цвет текста */
      color: #000 !important;
      /* цвет текста */ }
    .input__wrap.active .input_label {
      top: -12px;
      font-size: 12px; }
  .input_label {
    transition: all .2s;
    position: absolute;
    left: 10px;
    top: 5px; }

.input_basic {
  border: none;
  background: #FAFAFA;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 4px 10px;
  border-radius: 6px; }
  .input_basic__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 25px 0; }
  .input_basic textarea:-moz-placeholder {
    color: #586B7A; }
  .input_basic textarea::-webkit-input-placeholder {
    color: #586B7A; }
  .input_basic input::-webkit-input-placeholder {
    color: #586B7A; }
  .input_basic input::-moz-placeholder {
    color: #586B7A; }
  .input_basic input:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 50px #FAFAFA !important;
    /* Цвет фона */
    -webkit-text-fill-color: #000 !important;
    /* цвет текста */
    color: #000 !important;
    /* цвет текста */ }

.textarea_basic {
  resize: none;
  height: 90px; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }
