.btn_red {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EB5757;
  width: 210px;
  color: white;
  height: 62px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  text-transform: uppercase;
  text-align: center; }
  .btn_red img {
    margin-left: 10px; }
  .btn_red:hover {
    box-shadow: 0px 5px 5px rgba(139, 139, 139, 0.29); }

.btn_white {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
  width: 210px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  height: 62px;
  cursor: pointer;
  padding: 10px; }
  .btn_white img {
    margin-right: 10px; }
  .btn_white:hover {
    box-shadow: 0px 5px 5px rgba(88, 86, 86, 0.29); }
