.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 2%;
  box-shadow: 0px -27px 30px rgba(0, 0, 0, 0.1); }
  @media (max-width: 1300px) {
    .footer {
      justify-content: center; } }
  @media (max-width: 900px) {
    .footer {
      flex-direction: column; } }
  .footer_links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    .footer_links a {
      display: block;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #000000;
      margin-right: 50px; }
      @media (max-width: 600px) {
        .footer_links a {
          margin: 0 20px 20px 0; } }
      .footer_links a:last-child {
        margin-right: 0; }
  .footer_payment {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .footer_payment__title {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      /* identical to box height */
      color: #000000; }
    .footer_payment a {
      margin-left: 30px; }
  .footer div {
    margin-right: 30px; }
    @media (max-width: 900px) {
      .footer div {
        margin-right: 0;
        margin-bottom: 30px; } }
    .footer div:last-child {
      margin-right: 0; }
