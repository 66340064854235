.regular_title {
  font-weight: 400;
  font-stretch: normal;
  font-style: normal; }
  .regular_title_0 {
    font-size: 12px; }
  .regular_title_1 {
    font-size: 14px; }
  .regular_title_2 {
    font-size: 16px; }
  .regular_title_3 {
    font-size: 18px; }
  .regular_title_6 {
    font-size: 30px; }

.bold_title {
  font-weight: 700;
  font-stretch: normal;
  font-style: normal; }
  .bold_title_0 {
    font-size: 12px; }
  .bold_title_1 {
    font-size: 14px; }
  .bold_title_2 {
    font-size: 16px; }

.color_black {
  color: #000000; }

.color_white {
  color: #fff; }

.color_red {
  color: #CF1A20; }

.color_gray {
  color: #828282; }

.color_gray1 {
  color: #333333; }
