.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 2%;
  width: 100%;
  z-index: 2;
  background: #FFFFFF;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.1); }
  @media (max-width: 1000px) {
    .header {
      height: 55px;
      padding: 0;
      position: fixed; } }
  .header_nav_mob {
    opacity: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 55px;
    left: -500px;
    max-width: 320px;
    width: 100%;
    height: 100%;
    background: #030E1E;
    box-shadow: 30px 30px 30px rgba(0, 0, 0, 0.4);
    z-index: 100;
    transition: all 0.5s;
    padding: 50px; }
    .header_nav_mob a {
      font-size: 25px;
      margin-bottom: 25px;
      color: white; }
  .header_left {
    display: flex;
    align-items: center; }
    @media (max-width: 1000px) {
      .header_left__logo img {
        height: 40px; } }
  @media (max-width: 1000px) {
    .header_links {
      display: none; } }
  .header_links a {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin-right: 50px; }
    .header_links a:last-child {
      margin-right: 0; }
  .header_btn {
    display: flex; }
    @media (max-width: 1000px) {
      .header_btn .btn_white,
      .header_btn .btn_red {
        height: 45px; } }
    @media (max-width: 600px) {
      .header_btn .btn_white,
      .header_btn .btn_red {
        width: auto; } }
  .header_hamburger {
    overflow: hidden;
    position: relative;
    width: 55px;
    height: 55px;
    background: #030E1E;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    display: none; }
    @media (max-width: 1000px) {
      .header_hamburger {
        display: flex; } }
  .header_line {
    transition: all .2s;
    position: absolute;
    width: 25px;
    height: 3px;
    border-radius: 18px;
    margin: 4px 0;
    cursor: pointer;
    background: #FFFFFF; }
    .header_line_1 {
      top: 16px;
      left: 14px; }
    .header_line_2 {
      top: 22px;
      left: 14px; }
    .header_line_3 {
      top: 28px;
      left: 14px; }
  .header.active .header_line_1 {
    top: 22px;
    left: 14px;
    transform: rotate(45deg); }
  .header.active .header_line_2 {
    left: -100px; }
  .header.active .header_line_3 {
    top: 22px;
    left: 14px;
    transform: rotate(-45deg); }
  .header.active .header_nav_mob {
    opacity: 1;
    left: 0; }
