.swiper-button-next {
  position: absolute;
  right: 0;
  width: 87px;
  height: 87px;
  background: #000000; }
  .swiper-button-next:after {
    font-size: 30px;
    color: white; }

.swiper-button-prev {
  position: absolute;
  left: 0;
  width: 87px;
  height: 87px;
  background: #000000; }
  .swiper-button-prev:after {
    font-size: 30px;
    color: white; }

.help_slider .swiper-slide {
  height: 423px; }
  .help_slider .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
