main {
  position: relative; }

.unselectable {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    not supported by any browser */ }

.padding_navbar {
  padding-top: 165px; }

.section_height {
  min-height: calc(100vh - 137px); }

.container {
  position: relative;
  padding: 0px 2%;
  width: 100%; }

.upper_text {
  text-transform: uppercase; }

.swiper-button::after {
  transform: scale(0.6);
  color: #5D6B7C; }

.swiper-button::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background: #F2F2F2;
  opacity: 0.5;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%; }

.swiper-button.swiper-button-disabled::after {
  color: #fff; }

.swiper-pagination-bullet {
  position: relative;
  width: 5px;
  margin: 0px 15px !important;
  height: 5px;
  opacity: 1;
  background: #000 !important; }
  .swiper-pagination-bullet:after {
    content: '';
    transition: all .2s;
    position: absolute;
    width: 5px;
    height: 5px;
    border: 1px solid #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%; }
  .swiper-pagination-bullet-active:after {
    width: 30px;
    height: 30px; }

.absolute_center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.center {
  position: absolute;
  left: 50%;
  transform: translate(-50%); }

.first_padding {
  padding-top: 0px; }
  @media (max-width: 1000px) {
    .first_padding {
      padding-top: 55px !important; } }
