.isra-pale-children__top-container {
  width: 90%;
  margin: 5% 5% 45px; }

.isra-pale-children__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-left: 13px solid #eb5757; }

.isra-pale-children__title {
  max-width: 80%;
  text-align: center;
  font-size: 50px;
  line-height: 61px;
  font-weight: 700; }

.isra-pale-children__text {
  max-width: 80%;
  margin: 0 auto; }
  .isra-pale-children__text p {
    font-size: 24px;
    line-height: 29px;
    font-weight: 500; }

.isra-pale-children__donate-link {
  max-width: 320px;
  width: 100%; }

.isra-pale-children__donate-btn {
  width: 100%;
  margin-top: 20px; }

.isra-pale-children__images {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)); }
  .isra-pale-children__images-item:first-child {
    grid-column: span 2 / span 2; }
  .isra-pale-children__images-item img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

@media (max-width: 900px) {
  .isra-pale-children__top-container {
    width: 100%;
    margin: 5% 0 45px; } }

@media (max-width: 720px) {
  .isra-pale-children__title {
    font-size: 38px;
    line-height: 48px; } }

@media (max-width: 500px) {
  .isra-pale-children__donate-link {
    max-width: 220px; } }
